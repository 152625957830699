/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC} from 'react'
import {Redirect, Switch, Route} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {Login} from '../modules/auth/components/Login'
import {Registration} from '../modules/auth/components/Registration'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import {ICONS} from '../../_custom/partials/components/Icon'
import {MODULES, TModuleKey, VIEWS} from '../../_custom/helpers/RoutingHelpers'
import {Settings} from '../modules/accounts/components/settings/Settings'
import {UsersCreate, UsersDelete, UsersEdit, UsersList} from '../modules/users'
import {RolesCreate, RolesDelete, RolesEdit, RolesList} from '../modules/roles'
import {EditProfile} from '../modules/profile'
import {TenantsCreate, TenantsDelete, TenantsEdit, TenantsList} from '../modules/tenants'
import {CustomersCreate, CustomersDelete, CustomersEdit, CustomersList} from '../modules/customers'
import {ProductCategoriesCreate, ProductCategoriesDelete, ProductCategoriesEdit, ProductCategoriesList } from '../modules/productCategories'
import {ProductsCreate, ProductsDelete, ProductsEdit, ProductsList} from '../modules/products'
import {SalesCreate, SalesDelete, SalesEdit, SalesList} from '../modules/sales'
import {AdsCreate, AdsDelete, AdsEdit, AdsList} from '../modules/ads'
import {TenantGroupsCreate, TenantGroupsDelete, TenantGroupsEdit, TenantGroupsList} from '../modules/tenantGroups'
import {
  BlogCategoriesCreate,
  BlogCategoriesDelete,
  BlogCategoriesEdit,
  BlogCategoriesList,
} from '../modules/blogCategories'
import {BlogPostsCreate, BlogPostsDelete, BlogPostsEdit, BlogPostsList} from '../modules/blogPosts'
import {TenantPostsCreate, TenantPostsDelete, TenantPostsEdit, TenantPostsList} from '../modules/tenantPosts'

export type TAppRoute = {
  views: Array<'MENU'|'ACTION'|'DIALOG'|string>,
  routeKey: string,
  path: string,
  context: TModuleKey,
  component: React.FC<any>,
  icon?: string
}

export const ROUTES:Array<TAppRoute> = [
  {
    routeKey: 'AUTH.LOGIN',
    path: `/auth/login`,
    component: Login,
    context: MODULES.AUTH,
    views: []
  },
  {
    routeKey: 'AUTH.REGISTER',
    path: `/auth/registration`,
    component: Registration,
    context: MODULES.AUTH,
    views: []
  },
  {
    routeKey: 'AUTH.FORGOT',
    path: `/auth/password/forgot`,
    component: ForgotPassword,
    context: MODULES.AUTH,
    views: []
  },
  // {
  //   routeKey: 'AUTH.RESET',
  //   path: `/auth/password/reset/:token`,
  //   component: ResetPassword,
  //   context: 'auth',
  //   views: []
  // },
  // Profile
  {
    routeKey: 'USER.PROFILE.EDIT',
    path: `/${MODULES.PROFILE}/edit`,
    icon: ICONS.EDIT,
    component: EditProfile,
    context: MODULES.PROFILE,
    views: []
  },
  {
    routeKey: 'USER.PASSWORD.EDIT',
    path: `/${MODULES.PROFILE}/password/edit`,
    icon: ICONS.EDIT,
    component: Settings,
    context: MODULES.PROFILE,
    views: []
  },
  // users
  {
    routeKey: 'USERS.NEW',
    path: `/${MODULES.USERS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: UsersCreate,
    context: MODULES.USERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'USERS.EDIT',
    path: `/${MODULES.USERS}/:id/edit`,
    icon: ICONS.EDIT,
    component: UsersEdit,
    context: MODULES.USERS,
    views: []
  },
  {
    routeKey: 'USERS.DELETE',
    path: `/${MODULES.USERS}/:id/delete`,
    icon: ICONS.USERS_DELETE,
    component: UsersDelete,
    context: MODULES.USERS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'USERS.LIST',
    path: `/${MODULES.USERS}/list`,
    icon: ICONS.USERS,
    component: UsersList,
    context: MODULES.USERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Roles
  {
    routeKey: 'ROLES.NEW',
    path: `/${MODULES.ROLES}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: RolesCreate,
    context: MODULES.ROLES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'ROLES.EDIT',
    path: `/${MODULES.ROLES}/:id/edit`,
    icon: ICONS.EDIT,
    component: RolesEdit,
    context: MODULES.ROLES,
    views: []
  },
  {
    routeKey: 'ROLES.DELETE',
    path: `/${MODULES.ROLES}/:id/delete`,
    icon: ICONS.ROLES_DELETE,
    component: RolesDelete,
    context: MODULES.ROLES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'ROLES.LIST',
    path: `/${MODULES.ROLES}/list`,
    icon: ICONS.ROLES,
    component: RolesList,
    context: MODULES.ROLES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Tenants
  {
    routeKey: 'TENANTS.NEW',
    path: `/${MODULES.TENANTS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: TenantsCreate,
    context: MODULES.TENANTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'TENANTS.EDIT',
    path: `/${MODULES.TENANTS}/:id/edit`,
    icon: ICONS.EDIT,
    component: TenantsEdit,
    context: MODULES.TENANTS,
    views: []
  },
  {
    routeKey: 'TENANTS.DELETE',
    path: `/${MODULES.TENANTS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: TenantsDelete,
    context: MODULES.TENANTS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'TENANTS.LIST',
    path: `/${MODULES.TENANTS}/list`,
    icon: ICONS.TENANTS,
    component: TenantsList,
    context: MODULES.TENANTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Customers
  {
    routeKey: 'CUSTOMERS.NEW',
    path: `/${MODULES.CUSTOMERS}/new`,
    icon: ICONS.CUSTOMERS_CREATE,
    component: CustomersCreate,
    context: MODULES.CUSTOMERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'CUSTOMERS.EDIT',
    path: `/${MODULES.CUSTOMERS}/:id/edit`,
    icon: ICONS.EDIT,
    component: CustomersEdit,
    context: MODULES.CUSTOMERS,
    views: []
  },
  {
    routeKey: 'CUSTOMERS.DELETE',
    path: `/${MODULES.CUSTOMERS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: CustomersDelete,
    context: MODULES.CUSTOMERS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'CUSTOMERS.LIST',
    path: `/${MODULES.CUSTOMERS}/list`,
    icon: ICONS.CUSTOMERS,
    component: CustomersList,
    context: MODULES.CUSTOMERS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Product Categories
  {
    routeKey: 'PRODUCT_CATEGORIES.NEW',
    path: `/${MODULES.PRODUCT_CATEGORIES}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: ProductCategoriesCreate,
    context: MODULES.PRODUCT_CATEGORIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'PRODUCT_CATEGORIES.EDIT',
    path: `/${MODULES.PRODUCT_CATEGORIES}/:id/edit`,
    icon: ICONS.EDIT,
    component: ProductCategoriesEdit,
    context: MODULES.PRODUCT_CATEGORIES,
    views: []
  },
  {
    routeKey: 'PRODUCT_CATEGORIES.DELETE',
    path: `/${MODULES.PRODUCT_CATEGORIES}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: ProductCategoriesDelete,
    context: MODULES.PRODUCT_CATEGORIES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'PRODUCT_CATEGORIES.LIST',
    path: `/${MODULES.PRODUCT_CATEGORIES}/list`,
    icon: ICONS.PRODUCT_CATEGORIES,
    component: ProductCategoriesList,
    context: MODULES.PRODUCT_CATEGORIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Products
  {
    routeKey: 'PRODUCTS.NEW',
    path: `/${MODULES.PRODUCTS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: ProductsCreate,
    context: MODULES.PRODUCTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'PRODUCTS.EDIT',
    path: `/${MODULES.PRODUCTS}/:id/edit`,
    icon: ICONS.EDIT,
    component: ProductsEdit,
    context: MODULES.PRODUCTS,
    views: []
  },
  {
    routeKey: 'PRODUCTS.DELETE',
    path: `/${MODULES.PRODUCTS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: ProductsDelete,
    context: MODULES.PRODUCTS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'PRODUCTS.LIST',
    path: `/${MODULES.PRODUCTS}/list`,
    icon: ICONS.PRODUCTS,
    component: ProductsList,
    context: MODULES.PRODUCTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Sales
  {
    routeKey: 'SALES.NEW',
    path: `/${MODULES.SALES}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: SalesCreate,
    context: MODULES.SALES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'SALES.EDIT',
    path: `/${MODULES.SALES}/:id/edit`,
    icon: ICONS.EDIT,
    component: SalesEdit,
    context: MODULES.SALES,
    views: []
  },
  {
    routeKey: 'SALES.DELETE',
    path: `/${MODULES.SALES}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: SalesDelete,
    context: MODULES.SALES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'SALES.LIST',
    path: `/${MODULES.SALES}/list`,
    icon: ICONS.SALES,
    component: SalesList,
    context: MODULES.SALES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Ads
  {
    routeKey: 'ADS.NEW',
    path: `/${MODULES.ADS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: AdsCreate,
    context: MODULES.ADS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'ADS.EDIT',
    path: `/${MODULES.ADS}/:id/edit`,
    icon: ICONS.EDIT,
    component: AdsEdit,
    context: MODULES.ADS,
    views: []
  },
  {
    routeKey: 'ADS.DELETE',
    path: `/${MODULES.ADS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: AdsDelete,
    context: MODULES.ADS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'ADS.LIST',
    path: `/${MODULES.ADS}/list`,
    icon: ICONS.ADS,
    component: AdsList,
    context: MODULES.ADS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Tenant Groups
  {
    routeKey: 'TENANT_GROUPS.NEW',
    path: `/${MODULES.TENANT_GROUPS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: TenantGroupsCreate,
    context: MODULES.TENANT_GROUPS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'TENANT_GROUPS.EDIT',
    path: `/${MODULES.TENANT_GROUPS}/:id/edit`,
    icon: ICONS.EDIT,
    component: TenantGroupsEdit,
    context: MODULES.TENANT_GROUPS,
    views: []
  },
  {
    routeKey: 'TENANT_GROUPS.DELETE',
    path: `/${MODULES.TENANT_GROUPS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: TenantGroupsDelete,
    context: MODULES.TENANT_GROUPS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'TENANT_GROUPS.LIST',
    path: `/${MODULES.TENANT_GROUPS}/list`,
    icon: ICONS.TENANT_GROUPS,
    component: TenantGroupsList,
    context: MODULES.TENANT_GROUPS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Blog Categories
  {
    routeKey: 'BLOG_CATEGORIES.NEW',
    path: `/${MODULES.BLOG_CATEGORIES}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: BlogCategoriesCreate,
    context: MODULES.BLOG_CATEGORIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'BLOG_CATEGORIES.EDIT',
    path: `/${MODULES.BLOG_CATEGORIES}/:id/edit`,
    icon: ICONS.EDIT,
    component: BlogCategoriesEdit,
    context: MODULES.BLOG_CATEGORIES,
    views: []
  },
  {
    routeKey: 'BLOG_CATEGORIES.DELETE',
    path: `/${MODULES.BLOG_CATEGORIES}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: BlogCategoriesDelete,
    context: MODULES.BLOG_CATEGORIES,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'BLOG_CATEGORIES.LIST',
    path: `/${MODULES.BLOG_CATEGORIES}/list`,
    icon: ICONS.BLOG_CATEGORIES,
    component: BlogCategoriesList,
    context: MODULES.BLOG_CATEGORIES,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Blog Posts
  {
    routeKey: 'BLOG_POSTS.NEW',
    path: `/${MODULES.BLOG_POSTS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: BlogPostsCreate,
    context: MODULES.BLOG_POSTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'BLOG_POSTS.EDIT',
    path: `/${MODULES.BLOG_POSTS}/:id/edit`,
    icon: ICONS.EDIT,
    component: BlogPostsEdit,
    context: MODULES.BLOG_POSTS,
    views: []
  },
  {
    routeKey: 'BLOG_POSTS.DELETE',
    path: `/${MODULES.BLOG_POSTS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: BlogPostsDelete,
    context: MODULES.BLOG_POSTS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'BLOG_POSTS.LIST',
    path: `/${MODULES.BLOG_POSTS}/list`,
    icon: ICONS.BLOG_POSTS,
    component: BlogPostsList,
    context: MODULES.BLOG_POSTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  // Tenant Posts
  {
    routeKey: 'TENANT_POSTS.NEW',
    path: `/${MODULES.TENANT_POSTS}/new`,
    icon: ICONS.PLUS_SQUARE,
    component: TenantPostsCreate,
    context: MODULES.TENANT_POSTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
  {
    routeKey: 'TENANT_POSTS.EDIT',
    path: `/${MODULES.TENANT_POSTS}/:id/edit`,
    icon: ICONS.EDIT,
    component: TenantPostsEdit,
    context: MODULES.TENANT_POSTS,
    views: []
  },
  {
    routeKey: 'TENANT_POSTS.DELETE',
    path: `/${MODULES.TENANT_POSTS}/:id/delete`,
    icon: ICONS.DELETE_SQUARE,
    component: TenantPostsDelete,
    context: MODULES.TENANT_POSTS,
    views: [VIEWS.DIALOG]
  },
  {
    routeKey: 'TENANT_POSTS.LIST',
    path: `/${MODULES.TENANT_POSTS}/list`,
    icon: ICONS.TENANT_POSTS,
    component: TenantPostsList,
    context: MODULES.TENANT_POSTS,
    views: [VIEWS.MENU, VIEWS.ACTION]
  },
]

const ROUTE_KEYS = ROUTES.map(route=>route.routeKey)
type TRouteKey = typeof ROUTE_KEYS[number]
export const getRoute = (routeKey: TRouteKey) => {
  return ROUTES.find(r=>r.routeKey === routeKey)
}

const Routes: FC = () => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>
          <PrivateRoutes />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
