import React from "react";

export const getFullName = ({firstName, lastName = ''}) => {
  return `${firstName} ${lastName}`
}

export const FullName = ({user, ...props})=>{

  return (
    <a href="#" {...props}>
      {getFullName(user)}
    </a>
  )
}