import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as auth from '../../app/modules/auth'
import {authReducers} from '../../api/auth'
import {usersReducers} from '../../api/users'
import {rolesReducers} from '../../api/roles'
import {routesReducers} from '../../api/routes'
import {adsReducers} from '../../api/ads'
import {blogCategoriesReducers} from '../../api/blogCategories'
import {blogPostsReducers} from '../../api/blogPosts'
import {customersReducers} from '../../api/customers'
import {productCategoriesReducers} from '../../api/productCategories'
import {productsReducers} from '../../api/products'
import {salesReducers} from '../../api/sales'
import {tenantGroupsReducers} from '../../api/tenantGroups'
import {tenantPostsReducers} from '../../api/tenantPosts'
import {tenantsReducers} from '../../api/tenants'
import {notificationsReducers} from '../../api/notifications'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ...authReducers,
  ...usersReducers,
  ...rolesReducers,
  ...routesReducers,
  ...adsReducers,
  ...blogCategoriesReducers,
  ...blogPostsReducers,
  ...customersReducers,
  ...productCategoriesReducers,
  ...productsReducers,
  ...salesReducers,
  ...tenantGroupsReducers,
  ...tenantPostsReducers,
  ...tenantsReducers,
  ...notificationsReducers
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
