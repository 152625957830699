import QueryString from "qs";
import {AxiosInstance, AxiosResponse} from 'axios'
import {HTTP_CREATED, HTTP_DELETED, HTTP_INVALID_ENTITY, HTTP_UPDATED} from '../../_custom/helpers/AxiosHelpers'
import {toast} from "react-toastify";
import {getIntlMessage} from '../../_metronic/i18n/i18nProvider'
import {LOGIN_URI} from '../../api/auth/api'
import {FormattedMessage} from 'react-intl'


export default function setupAxios(axios: AxiosInstance, store: any) {
  axios.interceptors.request.use(
    (config) => {
      const {auth: {token}} = store.getState()

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      //todo
      if (config.method === 'post' && !config.url?.includes(LOGIN_URI)) {
        config.headers = {
          ...config.headers,
          'accept': 'application/json',
          'Content-Type': `multipart/form-data;`,
        }
      }

      config.paramsSerializer = (params:any) => {
        return QueryString.stringify(params)
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )



  axios.interceptors.response.use(
    (response:AxiosResponse):AxiosResponse => {
      if([HTTP_CREATED, HTTP_UPDATED, HTTP_DELETED].includes(response.status)) {
        toast(
          <div className='text-success'>
            <FormattedMessage id={`VALIDATION.HTTP_MESSAGE.${response.status}`} />
          </div>
        )
      }
    return response;
  }, (error)=> {
    let {status, data} = error.response;
    const m = data.message ? data.message : `VALIDATION.HTTP_MESSAGE.${status}`
    toast(
      toast(
        <span className='text-success'>
          <FormattedMessage id={m} />
        </span>
      )
    )

    switch (status){
      case HTTP_INVALID_ENTITY:{
        let err:Record<any, any> = {}
        Object.keys(data).forEach((k)=>{ err[k] = getIntlMessage({id:data[k][0]}) });
        data = err;
      } break;
    }
    return Promise.reject(error);
  })
}
