/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useMemo} from 'react'
import {FormattedMessage, useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'
import {useAuthState, useRoutesForAppState} from '../../../api/auth'
import {TAppRoute} from '../../routing/Routes'
import {MODULES, TModuleKey, VIEWS} from '../../../_custom/helpers/RoutingHelpers'
import {Link} from 'react-router-dom'
import {TenantEntityCard} from '../../modules/tenants'
import {Icon} from '../../../_custom/partials/components/Icon'
import {APP_NAME} from '../../../_custom/helpers/AssetHelpers'
import {getFullName} from '../../../_custom/partials/components/FullName'


const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xxl-4'>
        <MixedWidget2
          className='card-xl-stretch mb-xl-8'
          chartColor='danger'
          chartHeight='200px'
          strokeColor='#cb1e46'
        />
      </div>
      <div className='col-xxl-4'>
        <ListsWidget5 className='card-xxl-stretch' />
      </div>
      <div className='col-xxl-4'>
        <MixedWidget10
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='150px'
        />
        <MixedWidget11
          className='card-xxl-stretch-50 mb-5 mb-xl-8'
          chartColor='primary'
          chartHeight='175px'
        />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xxl-4'>
        <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
      </div>
      <div className='col-xl-8'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget2 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
        {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      </div>
    </div>
    {/* end::Row */}

    <div className='row g-5 gx-xxl-8'>
      <div className='col-xxl-4'>
        <MixedWidget8
          className='card-xxl-stretch mb-xl-3'
          chartColor='success'
          chartHeight='150px'
        />
      </div>
      <div className='col-xxl-8'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>
    </div>
  </>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {data: routes} = useRoutesForAppState();
  const {user} = useAuthState();

  const menuRoutes = useMemo(()=>{

    return routes.filter((route: TAppRoute)=>route.views.includes(VIEWS.MENU))
  }, [routes])

  const moduleKeys = (Object.keys(MODULES) as TModuleKey[])
    .filter((key)=> {
      return menuRoutes.findIndex((route:TAppRoute)=>route.context === MODULES[key]) !== -1
    })


  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {/*<DashboardPage />*/}

      <div className="pt-lg-10">
        <h1 className="fw-bolder fs-2qx text-gray-800 mb-15">
          <FormattedMessage
            id='WELCOME_TO_APP'
            values={{username: user ? getFullName(user) : '' ,name:APP_NAME}
            }/>
        </h1>
        {/*<div className="fw-bold fs-3 text-muted mb-15">*/}
        {/*  Plan your blog post by choosing a topic creating*/}
        {/*  <br/>an outline and checking facts.*/}
        {/*</div>*/}
      </div>

      {
        user && user.tenant &&
        <TenantEntityCard
          entity={user.tenant}
          className='mb-10 mw-50'
        />
      }

      <div className='card'>
        <div className="card-body p-5 px-lg-9 py-lg-8">
          <div className="mb-15">
            <h1 className="fs-2x text-dark mb-6">
              <FormattedMessage id='QUICK_ACTION' />
            </h1>
          </div>
          <div className="row mb-15">
            {
              moduleKeys
                .map((key)=>(
                  <div key={key} className="col-sm-4 mb-5">
                    <h3 className="fw-bolder text-dark mb-7">
                      <FormattedMessage id={key} />
                    </h3>

                    <div className="fw-bold fs-4">
                      {
                        menuRoutes
                          .filter((route: TAppRoute)=>route.context === MODULES[key])
                          .map((route: TAppRoute, index:number)=>(
                            <Link
                              key={route.routeKey}
                              to={route.path}
                              className="btn btn-bg-light btn-active-color-primary me-2 mb-2"
                            >
                              {
                                route.icon &&
                                <Icon
                                  path={route.icon}
                                  size={'1'}
                                />
                              }

                              <FormattedMessage id={route.routeKey} />
                            </Link>
                          ))
                      }
                    </div>
                  </div>
                ))
            }
          </div>

          {/*<div className="card mb-4 bg-light text-center mb-4">*/}
          {/*  <div className="card-body py-12">*/}
          {/*    <a href="#" className="mx-4">*/}
          {/*      <img src="/metronic8/demo1/assets/media/svg/brand-logos/facebook-4.svg" className="h-30px my-2" alt=""/>*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
