/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useAuthState} from '../../../api/auth'
import {Link} from 'react-router-dom'
import {MODULES} from '../../helpers/RoutingHelpers'
import {SlatePreview} from '../../partials/controls/SlateEditor/FormikSlateEditor'
import {defaultChannel} from '../../helpers/pusherHelpers'
import {Descendant} from 'slate'
import {fetchNotificationsForList, useNotificationsForList} from '../../../api/notifications'
import {useDispatch} from 'react-redux'

type TData = {
  objectId: number,
  content: Descendant[]
}

export const TenantRegistration:React.FC = () => {
  const dispatch = useDispatch();
  const {user} = useAuthState()
  const {metadata} = useNotificationsForList();
  const {pagination, filter} = metadata;

  useEffect(()=>{
    // 976
    if (user) {
      const event =  `TENANT-REGISTRATION.${user.id}`;
      defaultChannel.bind(event, (data: TData) => {
        // notify
        toast(
          <Link to={`/${MODULES.TENANTS.toLowerCase()}/${data.objectId}/edit`}>
            <SlatePreview value={data.content}/>
          </Link>
        );
        // refresh notifications
        dispatch(fetchNotificationsForList(metadata));
      })

      return ()=>{
        defaultChannel.unbind(event)
      }
    }

  }, [user, pagination.perPage, filter.filters])

  return <></>
}