import React from "react";
import {Icon, ICONS} from '../components/Icon'
import {TFormikControl} from './FormikBootstrapInput'
import GoogleMap from "google-map-react";

//todo move to env
const key = 'AIzaSyCdjO5TYYGA3Z3YAX3dNdovRh_HctBffk0'

const Market:React.FC = ({ ...props }) => {
  console.log(props)
  return (
    <Icon
      style={{
        top: '50%',
        left: '50%',
        transform: "translate(-50%, -50%)"
      }}
      size='2x'
      variant='danger'
      path={ICONS.MAP_MARKER2}
    />
  )

}

type TFormikMapCoordinatesPicker = React.FC<
  TFormikControl &
  {
    label?: string
    validation?: boolean
  }>
export const FormikMapCoordinatesPicker:TFormikMapCoordinatesPicker = ({
  form
}) => {
  const { values, setValues} = form;
  const { lat, lng } = values;

  return (
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMap
        options={(maps) => ({
          scrollwheel: false
        })}
        bootstrapURLKeys={{ key }}
        center={{ lat, lng }}
        defaultZoom={10}
        onClick={({ lat, lng })=>{
          setValues({
            ...values,
            lat,
            lng
          })
        }}
      >
        <Market />
      </GoogleMap>
    </div>
  )

}
