import {toAbsoluteUrl} from '../../_metronic/helpers'

export const APP_NAME = 'Restaurants Management App'
export const APP_LOGO_WITHOUT_TEXT = toAbsoluteUrl('/media/logos/logo-3.svg')
export const APP_LOGO = toAbsoluteUrl('/media/logos/logo-2-dark.svg')

export const toAbsoluteApiUrl = (pathname: string) => process.env.REACT_APP_API_URL + pathname
export const toAbsoluteUploadUrl = (pathname: string) => toAbsoluteApiUrl(`/uploads/${pathname}`)

export const DateFormat = "YYYY-MM-DD"
export const DateTimeFormat = `${DateFormat} HH:mm:ss`

export const SIZES = {
  xs: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 6,
}


// export const localField = (obj:Record<any, any>, property:string) => {
//   return obj[`${property}`]; //Remove me when server i18n is enabled
//   // const lang = useLang(); // fr, en nameFr, localField('desc')
//   // const capitalizedLg = lang.slice(0,1).toUpperCase() + lang.slice(1, lang.length)
//   // return `${name}${capitalizedLg}`
// }