
export type TModuleKey = 'USERS'|'ROUTES'|'ROLES'|'PROFILE'|'AUTH'|'ADS'|'BLOG_CATEGORIES'|'BLOG_POSTS'| 'CUSTOMERS'|'PRODUCT_CATEGORIES'|'PRODUCTS'|'SALES'|'TENANT_GROUPS'|'TENANT_POSTS'|'TENANTS'|'USER_NOTIFICATIONS'
// export type TModuleValue = 'users'|'routes'|'roles'|'profile'|'auth'|'ads'|'blog_categories'|'blog_posts'| 'customers'|'product_categories'|'products'|'sales'|'tenant_groups'|'tenant_posts'|'tenant'
type TModules = Record<TModuleKey, TModuleKey>
export const MODULES:TModules  = {
  USERS: 'USERS',
  ROUTES: 'ROUTES',
  ROLES: 'ROLES',
  PROFILE: 'PROFILE',
  AUTH: 'AUTH',
  ADS: 'ADS',
  BLOG_CATEGORIES: 'BLOG_CATEGORIES',
  BLOG_POSTS: 'BLOG_POSTS',
  CUSTOMERS: 'CUSTOMERS',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_CATEGORIES: 'PRODUCT_CATEGORIES',
  SALES: 'SALES',
  TENANTS: 'TENANTS',
  TENANT_GROUPS: 'TENANT_GROUPS',
  TENANT_POSTS: 'TENANT_POSTS',
  USER_NOTIFICATIONS: 'USER_NOTIFICATIONS',
}



export const VIEWS = {
  MENU: 'MENU',
  ACTION: 'ACTION',
  DIALOG: 'DIALOG'
}